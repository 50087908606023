import { ChoiceType } from "./choiceType";
import { TemplateEnum } from "./templateEnum";
import { CustomAnswerTypesEnum } from "./customAnswerTypesEnum";
import { NpsInfoType } from "./npsInfoType";
import { CsiType } from "./csiType";
import { CriteriaType } from "./criteriaType";
import { I18n } from "react-redux-i18n";
import { TextTranslationType } from "./textTranslationType";
import { LoyaltyInfoType } from "./loyaltyIndexType";

export type AnswerTemplateType = {
  readonly addCustomAnswer: boolean;
  readonly answerChoices: Array<ChoiceType>;
  readonly type: TemplateEnum;
  readonly csiParams: Array<CsiType>;
  readonly criteria: Array<CriteriaType>;
  readonly csiScale?: CsiScale;
  readonly actionLabel?: string;
  readonly customAnswerTextKk?: string;
  readonly customAnswerTextRu?: string;
  readonly customAnswerTextEn?: string;
  readonly customAnswerIsRequired?: boolean;
  readonly customAnswerType?: CustomAnswerTypesEnum;
  readonly npsInfoParams?: NpsInfoType;
  readonly loyaltyIndexType?: LoyaltyInfoType;
  readonly textTranslations?: Array<TextTranslationType>;
  readonly highestScoreLabel?: string;
  readonly lowestScoreLabel?: string;
};

export enum CsiScale {
  TEN_POINTS = "TEN_POINTS",
  FIVE_POINTS = "FIVE_POINTS",
  BOOLEAN = "BOOLEAN",
}

export const csiScaleTitles = (): Record<CsiScale, string> => {
  return {
    [CsiScale.TEN_POINTS]: `10 ${I18n.t("balls")}`,
    [CsiScale.FIVE_POINTS]: `5 ${I18n.t("balls")}`,
    [CsiScale.BOOLEAN]: I18n.t("yesNo"),
  };
};
