import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import InputFormItemComponent from "./form/items/input";

const ScoreLabelInputComponent: FC = () => {
  return (
    <div>
      <InputFormItemComponent
        maxCharacters={30}
        required={false}
        label={I18n.t("highestScoreLabel")}
        name={["answerTemplate", "highestScoreLabel"]}
        style={{ width: 300 }}
      />
      <InputFormItemComponent
        maxCharacters={30}
        required={false}
        label={I18n.t("lowestScoreLabel")}
        name={["answerTemplate", "lowestScoreLabel"]}
        style={{ width: 300 }}
      />
    </div>
  );
};

export { ScoreLabelInputComponent };
