import "./index.scss";
import React, { FC, useState } from "react";
import { Button, Form, Input } from "antd";
import HiddenValueFormItemComponent from "../../../../../components/form/items/hiddenValue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getNextOrderValue } from "../../../utils";
import { I18n } from "react-redux-i18n";
import MentionFormItemComponent from "../../../../../components/form/items/mention";
import { SurveyLanguage } from "../../../../../types/language";
import EmptyNA from "../../../../../components/emptyNA";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { QuestionType } from "../../../../../types/questionType";
import { DEFAULT_QUESTION } from "../../../../../utils";
import { sortByOrder } from "../../../../../utils/sortByOrder";
import isWelcome from "../../../../../utils/welcomeSmsChecker";

type TableContentProps = {
  readonly disabled: boolean;
  readonly dataKey: string;
  readonly langIndex: number;
  readonly lang: SurveyLanguage;
  readonly addButtonLabel: string;
};

export const GrowingList: FC<TableContentProps> = (props) => {
  const { disabled, dataKey, langIndex, lang, addButtonLabel } = props;

  const [initialized, setInitialized] = useState(false);

  const initializeFields = (add: (defaultValue?: any) => void, getFieldValue: (name: any) => any) => {
    const data = getFieldValue(["answerTemplate", dataKey]) || [];
    if (data.length === 0 && !initialized) {
      add({
        order: getNextOrderValue(data || []),
      });
      setInitialized(true);
    }
  };

  return (
    <Form.List name={["answerTemplate", dataKey]}>
      {(fields, { add, remove }) => (
        <>
          {fields?.length ? (
            fields.map(({ key, name }) => (
              <Input.Group key={key} className={"params-input-group"}>
                <HiddenValueFormItemComponent name={[name, "order"]} />
                <HiddenValueFormItemComponent name={[name, "textTranslations", langIndex, "language"]} value={lang} />
                <Form.Item label={I18n.t("variant") + ` №${name + 1}`} style={{ width: "100%" }}>
                  <MentionFormItemComponent
                    name={[name, "textTranslations", langIndex, "text"]}
                    label={I18n.t("variant") + ` №${name + 1}`}
                    required
                    rows={2}
                    noStyle
                  />
                </Form.Item>
                {!disabled && <MinusCircleOutlined onClick={() => remove(name)} />}
              </Input.Group>
            ))
          ) : (
            <EmptyNA />
          )}
          {!disabled && (
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                initializeFields(add, getFieldValue);
                return (
                  <Button
                    style={{ marginBottom: 24 }}
                    type="dashed"
                    onClick={() => {
                      const data = getFieldValue(["answerTemplate", dataKey]);
                      add({
                        order: getNextOrderValue(data || []),
                      });
                    }}
                    icon={<PlusOutlined />}
                  >
                    {addButtonLabel}
                  </Button>
                );
              }}
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
};

export const useQuestionEditPageSelectorsState = () => {
  return useSelector((state: RootState) => ({
    surveyId: state.surveys.current?.surveyId,
    question: state.questions.current,
    questions: state.questions.list,
    channel: state.surveys.channel,
    surveyColor: state.surveys.current?.themeColor,
    languages: state.languages.surveyLanguages,
  }));
};

export const NEW_QUESTION = (order: number) =>
  ({
    ...DEFAULT_QUESTION,
    order: order,
    name: "",
  } as unknown as QuestionType);

export const mapTextToSurveyUrl = (lang: SurveyLanguage) => ({ language: lang, text: "${SURVEY_URL} " });

export const cutWelcomeMessage = (questions: Array<QuestionType>) => {
  return questions.filter((q) => !isWelcome(q)).sort(sortByOrder);
};
