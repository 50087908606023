import { FC, useCallback, useEffect } from "react";
import Filters from "./filters";
import Table from "./table";
import { useActions } from "../../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { TabKeysEnum } from "../helpers";

const AnswersTab: FC<{ activeKey: TabKeysEnum }> = ({ activeKey }) => {
  const { id: surveyId } = useParams();
  const { getSurveyInstances } = useActions();
  const { pageable } = useSelector((state: RootState) => state.surveys.surveyInstance);

  const loadInstances = useCallback(() => {
    if (surveyId) {
      getSurveyInstances(surveyId);
    }
  }, [surveyId]);

  useEffect(loadInstances, [pageable.page, pageable.size]);

  return (
    <div>
      <Filters activeKey={activeKey} />
      <Table activeKey={activeKey} />
    </div>
  );
};

export default AnswersTab;
